// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_NPtZh{display:flex;flex-direction:column;height:100%}.chip_kv8ua{align-items:center;background-color:var(--color-white);border-radius:var(--border-radius-medium);justify-content:center;padding:.25rem}.chip_kv8ua,.dot_BPSLO{display:flex}.dot_BPSLO{background-color:var(--color-red-500);border-radius:50%;height:.75rem;width:.75rem}.dot_BPSLO.active_hO63l{background-color:var(--color-green-700)}.icon_HXexL{align-items:center;display:flex;justify-content:center}.label_NVuC5{display:flex;margin-left:.25rem}.table-naked{border-collapse:collapse;width:100%}.table-naked td{padding:.25rem 1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_NPtZh",
	"chip": "chip_kv8ua",
	"dot": "dot_BPSLO",
	"active": "active_hO63l",
	"icon": "icon_HXexL",
	"label": "label_NVuC5"
};
module.exports = ___CSS_LOADER_EXPORT___;
